import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://81f73cc5ecf079570ae3b023a31e542e@sentry.isis-ic.com/14",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0,
  integrations: [Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});